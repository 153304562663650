html {
  min-height: 100%;
}
body {
  min-height: 100vh;
  background-color: #ededf9;
}
.login-form {
  padding-bottom: 30px;
  & .class-code-form {
    margin-top: 55px;
    & .class-code-title {
      font-family: Graphie;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #1a1a1a;
      margin-bottom: 26px;
      margin-top: 15px;
    }
    & .class-code-container {
      display: flex;
      position: relative;
      margin: auto;
      margin-bottom: 20px;
      text-align: center;
      justify-content: center;
      border: 2px solid #4F52C3;
      border-radius: 37.5px;
      background-color: white;
      width: 570px;
      height: 160px;
      > div {
        margin-right: 23px;
        &:last-child {
          margin-right: 0;
        }
        > input {
          width: 100px;
          height: 160px;
          color: transparent;
          text-shadow: 0 0 0 #4f52c3;
          z-index: 2;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: normal;
          font-size: 64px;
          border: 0px;
          text-align: center;
          text-transform: uppercase;
          width: 64px;
          height: 155px;
          font-size: 48px;
          background-color: transparent;
          &:hover,
          &:active,
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        > div {
          position: absolute;
          width: 64px;
          height: 2px;
          background-color: #4f52c3;
          top: 120px;
          margin-left: 18px;
          margin-right: 18px;
          width: 64px;
          top: 123px;
          margin-left: 0px;
          margin-right: 11px;
        }
      }
    }
    & .class-code-error-text {
      font-family: Graphie;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #fd825d;
      margin-top: 25px;
      text-align: center;
    }
    @media screen and (max-width: 1440px) {
      & .class-code-container {
        width: 570px;
        height: 160px;
        > div {
          margin-right: 20px;
          > input {
            width: 64px;
            height: 155px;
            font-size: 48px;
          }
          > div {
            width: 64px;
            top: 123px;
            margin-left: 0px;
            margin-right: 11px;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      & .class-code-container {
        width: 100%;
        height: 98px;
        > div {
          margin-right: 7px;
          > input {
            width: 51px;
            height: 76px;
            font-size: 36px;
          }
          > div {
            width: 36px;
            top: 76px;
            margin-left: 7px;
            margin-right: 7px;
          }
        }
      }
    }
    @media screen and (max-width: 485px) {
      & .class-code-container {
        width: 100%;
        height: 76px;
        border-radius: 24px;
        > div {
          margin-right: 7px;
          > input {
            width: 41px;
            height: 60px;
            font-size: 36px;
          }
          > div {
            width: 30px;
            top: 57px;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
    & .class-code-link-margin {
      margin-top: 50px;
    }
  }
  & .login-form-footer-link {
    font-family: Graphie;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #1a1a1a;
    text-align: center;
    & a {
      line-height: 14px;
      color: #4f52c3;
    }
  }
  & .parent-register-link {
    margin-top: 20px;
  }
  & .margin-top-30 {
    margin-top: 30px;
  }
  & .teacher-register-link {
    margin-top: 30px;
  }
  & .margin-bottom-30 {
    margin-bottom: 100px;
  }

  & .email-password-form {
    max-width: 384px;
    margin: auto;
    & p {
      font-family: Graphie;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #1a1a1a;
    }
    & input {
      border-radius: 5px;
      outline: none;
      border: 1px solid #cacad8;
      width: 100%;
      height: 40px;
      margin-top: 8px;
      padding-left: 5px;
    }
    & .password-wrapper {
      margin-top: 18px;
      text-align: left;
      & .password-label-forgot-link {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      & .password-input-error-label {
        text-align: left;
      }
    }
    & .forgot-password-link {
      font-family: Graphie;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: right;
      text-decoration-line: underline;
      color: #4f52c3;
    }
    & .button-wrapper {
      width: 100%;
      margin: 28px auto 0;
    }
  }

  @media screen and (max-width: 1200px) {
    & .email-password-form {
      max-width: 484px;
    }
    & .parent-register-link {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    & .teacher-register-link {
      margin-top: 20px;
    }
  }

  & .login-form-error {
    font-family: Graphie;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #d81430 !important;
    position: relative;
    top: 0;
    padding-bottom: 7px;
    text-align: left;
    width: 100%;
  }

  // Social Login buttons

  & .social-or-line {
    position: absolute;
    top: -36px;
    width: 100%;
    border-bottom: 1px solid #dedede;
    text-align: center;
    line-height: 0.1em;
    & > span {
      padding: 0 10px;
      background: #ededf9;
    }
  }

  & .social-login-button-container {
    display: block;
    max-width: 384px;
    min-width: 300px;
    margin: 68px auto 0;
    position: relative;
    justify-content: center;
    & > a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      border: 1px solid #dcdcea;
      border-radius: 8px;
      background-color: #ffffff;
      min-width: 240px;
      margin: 0 0 12px;
      color: #031148;
      padding-left: 86px;
      &:last-of-type {
        margin: 0;
      }
      & > img {
        height: 25px;
        width: 25px;
      }
      & > p {
        margin-left: 23px;
      }
    }
    @media screen and (max-width: 1200px) {
      max-width: 484px;
      min-width: 300px;
      & > a {
        padding-left: 139px;
      }
    }

    @media screen and (max-width: 400px) {
      & > a {
        padding-left: 70px;
      }
    }
  }
}

// Forgot Password Screen

.forgot_password_container {
  max-height: 940px;
  padding: 54px 0 40px;
  & .forgot-password-title {
    font-family: Graphie;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    color: #1a1a1a;
  }

  & .forgot-password-content-description {
    font-family: Graphie;
    max-width: 370px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #1a1a1a;
    margin: auto;
    margin-top: 28px;
  }

  & .forgot-password-cta-container {
    max-width: 370px;
    margin: auto;
    align-items: center;
    margin-top: 25px;
  }

  & .content-identifier {
    font-family: Graphie;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #1a1a1a;
    margin: auto;
  }

  & .js-error-login-form {
    font-family: Graphie;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #d81430 !important;
    text-align: left;
    width: 100%;
  }

  & .forgot-password-input {
    border-radius: 5px;
    outline: none;
    border: 1px solid #cacad8;
    width: 100%;
    height: 40px;
    padding-left: 5px;
    margin: auto;
    margin-top: 10px;
  }

  @media screen and (max-width: 1200px) {
    padding: 60px 0 30px;
    & .forgot-password-content-description {
      max-width: 484px;
    }
    & .forgot-password-cta-container {
      max-width: 484px;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 22px 0 24px;
    .forgot-password-content-description {
      max-width: 340px;
    }
    .forgot-password-cta-container {
      max-width: 340px;
      margin-top: 40px;
    }
  }
}

.new-login-cta-button {
  width: 100%;
  height: 56px;
  border-radius: 28px;
  background: #4f52c3;
  border: none;
  color: #ffffff;
  margin: auto;
  display: block;
  outline: none;
  margin-top: 30px;
}
