.container {
  margin-right: auto;
  margin-left: auto;
  width: 960px;
  max-width: 100%;
}

.container:before,
.container:after {
  content: ' ';
  display: table;
}

@media (max-width: 959px) {
  .container {
    width: auto;
    max-width: none;
    min-width: 0;
    padding: 0 10px;
  }
}
.sec-privacy {
  margin-top: 30px;
}
.sec-privacy .head-privacy {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .sec-privacy .head-privacy {
    font-size: 1.8em;
  }
}
