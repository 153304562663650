@import "../common/css-reset";
@import '../common/normalize';
@import '../common/global-variables';
@import '../mixins/fonts';
@import '../common/media';
@import '../loggedout/shared.scss';
@import '../themes/global/colors';

* {
  box-sizing: border-box;
  font-family: "graphie", "sans-serif";
}
html {
  font-size: 10px;
}
body {
  font-family: Graphie;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.032rem;
}



@import '../loggedout/loggedout-home-page';
@import '../loggedout/homepage-faqs';