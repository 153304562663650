@mixin position-top-left-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin position-top-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
@mixin position-left-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
@mixin custom-position-top-left($top, $left) {
    position: absolute;
    top: $top;
    left: $left;
}
@mixin custom-position-top-right($top, $right) {
    position: absolute;
    top: $top;
    right: $right;
}
@mixin custom-position-top-bottom($top, $bottom) {
    position: absolute;
    top: $top;
    bottom: $bottom;
}
@mixin custom-position-bottom-left($bottom, $left) {
    position: absolute;
    bottom: $bottom;
    left: $left;
}
@mixin custom-position-bottom-right($bottom, $right) {
    position: absolute;
    bottom: $bottom;
    right: $right;
}