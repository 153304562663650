.content-container {
  width: 80%;
  max-width: 1170px;
  margin: 0 auto;
  @media (min-width: $screen-lg-min) and (max-width: $screen-xl-min) {
    width: 90%;
  }
  @media (max-width: $screen-sm-min) {
    width: calc(100% - 17px);
  }
}
.full-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
.text-center {
  text-align: center;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix { display: inline-block; }
* html .clearfix { height: 1%; }
.clearfix { display: block; }

