@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin flex-horizontal-center {
    display: flex;
    justify-content: center;
}
@mixin flex-vertical-center {
    display: flex;
    align-items: center;
}
@mixin custom-flex($value1, $value2) {
    display: flex;
    justify-content: $value1;
    align-items: $value2;
}