/**
 holds the list of colors
**/
$paintChip: #4F52C3;
$violetBlue:  #3A3DB6;
$redDamask: #D3683A;
$persimmon:  #EF8A5E;
$levander:  #EDEDF9;
$success: #44ae9a;
$success100:  #57C1A0;
$success200: #3BA281;
$puertoRico: #49B99E;
$gossamer: #3FA390;
$orange: #BA6246;
$orange100:  #F9805D;
$orange200:  #FD825D;
$blue:  #1A63A6;
$navyBlue: #2284E2;
$grey: #D8D8E6;
$black1: #090A4A;
$darkBlue: #141975;
$purplishBlue: #292C96;
$meganticBlue: #358EB6;
$skyBlue: #6BCCF2;
$purple: #B746E6;
$snomo: #7AB5EE;
$lighDarkBlue: #020533;
$greyWhite: #C6C6D4;
$indigo: #8535A9;
$smalt:  #373BA3;
$bahamaBlue: #202487;
$portage: #888ad9;
$lavendarGrey: #c6c6d4;
$borderOrange: #EB6D45;
$mintGreen: #4BB29E;
$cassataPurple: #9D9FFC;
$white: #ffffff;
$yellow: #FFC543;
$goldenYellow: #FEC32E;
$brown: #673200;
$redWhite: #ddd;
$paymentRequestButtonGrey: #888888;
$curriculamGroupLinkLightBlue: #007CEA33;
$lightGreyBorder: #CACAD8;
$tuna: #363639;
$lightBlack: #1a1a1a;