@import '../slick-default.scss';
@import '../slicktheme-default.scss';

.slick-slide > div > div {
  outline: none;
}
.home-page-slider {
  position: relative;
  .slick-prev,
  .slick-next {
    width: 5.8rem;
    height: 14.2rem;
    z-index: 1;
    border: solid 0.1rem #dedede;
    background: $white;
    opacity: 0.9;
    z-index: 2;
    &:before {
      content: '';
      opacity: 1;
      display: inline-block;
      width: 2rem;
      height: 4rem;
      background-size: 100% 100%;
      background-image: url('images/loggedout/icons/curriculum_left.png');
    }
  }
  .slick-prev {
    left: 0;
    border-radius: 0 1rem 1rem 0;
  }
  .slick-next {
    right: 0;
    border-radius: 1rem 0 0 1rem;
    &:before {
      transform: rotate(180deg);
    }
  }
  .slick-slide {
    filter: opacity(0.5);
  }
  .slick-active {
    filter: none;
  }
}
.review-slider {
  .slick-prev,
  .slick-next {
    height: 4.6rem;
    width: 4.6rem;
    border-radius: 50%;
    background: $indigo;
    color: $white;
    &:before {
      content: "";
      opacity: 1;
      display: inline-block;
      width: 0.9rem;
      height: 1.5rem;
      background-size: 100% 100%;;
    }
    &:hover {
      background: $royal;
    }
  }
  .slick-prev {
    left: -6.4rem;
    &:before {
      background-image: url('images/loggedout/icons/iconLeftArrow.png');
    }
  }
  .slick-next {
    right: -6.4rem;
    &:before {
      background-image: url('images/loggedout/icons/iconRightArrow.png');
    }
  }
  .slick-dots {
    bottom: -4.0rem;
    li {
      margin: 0;
    }
    li.slick-active button:before {
      color: $indigo;
      opacity: 1;
    }
    li button:before {
      color: $indigo;
      opacity: 0.25;
      font-size: 2.7rem;
    }
  }
  .slick-slide {
    filter: opacity(0.5);
  }
  .slick-active {
    filter: none;
    z-index: 10;
  }
}
.le-slider {
  width: 80%;
  margin: 10rem auto 0;
  .slick-slide {
    filter: none;
    pointer-events: none;
  }
  .slick-dots {
    bottom: -4.0rem;
    li {
      margin: 0;
    }
    li.slick-active button:before {
      color: $indigo;
      opacity: 1;
    }
    li button:before {
      color: $indigo;
      opacity: 0.25;
      font-size: 3.2rem;
    }
  }
  @media (max-width: $screen-xl-min) {
    width: auto;
    margin: 10rem 0 0 0;
    .slick-slide {
      filter: opacity(0.5);
    }
    .slick-active {
      filter: none;
    }
  }
}
