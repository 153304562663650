.homepage-faq-section {
  background: $white;

  .faq-container {
    max-width: 120rem;
    display: flex;
    justify-content: center;
    margin: auto;
    @media only screen and (max-width: 1279px) {
      flex-direction: column;
    }
    @media only screen and (min-width: 768px) and (max-width: 1279px) {
      padding: 5.6rem 4.4rem;
    }
    @media only screen and (max-width: 767px) {
      padding: 5.6rem 1.6rem;
    }

    .faq-heading {
      @include font-Graphie(3.2, 5.2, 7);
      color: $lightBlack;
      text-align: center;
      @media only screen and (min-width: 1280px) {
        width: 50%;
        text-align: left;
      }
      @media only screen and (min-width: 768px) and (max-width: 1279px) {
        @include font-Graphie(3.0, 4.0, 7);
      }
      @media only screen and (max-width: 767px) {
        @include font-Graphie(2.4, 2.8, 7);
      }
    }

    ul.faq-items {
      @media only screen and (min-width: 1280px) {
        width: 50%;
        margin-left: 1.6rem;
      }

      li.faq-item {
        border-bottom: 1px solid $lightGreyBorder;
        padding: 1.1rem 0rem;
      
        .faq-question {
          display: flex;
          gap: 1.6rem;
          align-items: center;
          @include font-Graphie(1.6, 2.8, 6);
          color: $lightBlack;
          cursor: pointer;
          @media only screen and (min-width: 1280px) {
            margin-left: -3.2rem;
          }
          @media only screen and (max-width: 767px) {
            @include font-Graphie(1.6, 2.2, 6);
          }

          .expand_collapse_icon{
            display: block !important;
          }
          .question-text{
            letter-spacing: 0.02em;
          }
        }
        
        .faq-answer{
          margin-top: 1.1rem;
          @include font-Graphie(1.6, 2.8, 4);
          letter-spacing: 0.02em;
          color: $tuna;
          @media only screen and (min-width: 768px) and (max-width: 1279px) {
            @include font-Graphie(1.6, 2.2, 4);
            margin-left: 3.4rem;
          }
          @media only screen and (max-width: 767px) {
            @include font-Graphie(1.4, 2.0, 4);
            margin-left: 3.6rem;
          }
          &.hide{
            display: none;
          }
        }
      }
    }
  }
}