$general-font-family: 'Helvetica','Arial','sans-serif';
$base-font-size-px: 10;  // 10 denotes 10px == 1em
$base-font-size-em: 1;  // 1 denotes 1em == 10px

$white: #ffffff;
$black: #000000;
$light-orange: #f79202;
$dark-orange: #c47402;
$math-blue: #034eac;
$error-red: #ff5b5d;
$black2: #141953;
$modal-title-bg: $white;
$modal-title-color: #353637;

// parent dashboard colours
$denim: #0f6ec9;
$anakiwa: #a7d0ff;
$dodger-blue: #46a1f9;
$science-blue: #026cd2;
$picton-blue: #3292ED;
$ice-cold: #b8f7e8;
$cornflower-blue: #7867ef;
$indigo-light: #6351d0;
$heliotrope: #9687ff;
$melrose: #b6abff;

// new website colours
$titan-white: #f3f3ff;
$indigo: #4f52c3;
$indigo-dark: #4b5ebd;
$deep-cove: #08064b;
$cod-grey: #1A1A1A;
$royal: #141975;
$new-black: #1a1a1a;
$alto: #dedede;
$curious-blue: #2284e2;
$deep-koamaru: #141975;
$deep-cove-light: #090a4a;
$white-lilac: #ededf9;
$bombay: #b5bbc1;
$hawkes: #dedffc;
$coral: #FD825D;
$soundBlue: #797CDB;
$paleBlue: #D3E6F9;
$palePink: #F1DAFA;

//signup popup
$vivid-blue : #1877f2;
$sonic-silver : #797878;
$subheading-color : #888888;
$dark-grey: #a4a4a4;
$paris-white : #c2c3cf;
$pale-red : #ec8569;

//purchase-page-ptl-flow-mobile
$medium-blue: #0045aa;
$bluish-green: #A5F9E8;
$blackly: #040404;
$brick-red: #EB6D45;
$pinky : #D500A1;

//tutoring page
$black-light: #565656;
$paymentRequestButtonGrey: #888888;