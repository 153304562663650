@import '../common/css-reset';
@import '../common/normalize';
@import '../common/global-variables';
@import '../common/media';
@import '../loggedout/shared.scss';
@import '../loggedout/slider.scss';
@import '../loggedout/privacy.scss';
@import '../loggedout/signin';

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

$min_width: 320px;
$max_width: 1440px;
$min_font: 8px;
$max_font: 10px;

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

* {
  box-sizing: border-box;
  font-family: 'graphie', 'sans-serif';
}
body {
  font-family: Graphie;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.032rem;
}

.video-variant-overflow-hidden {
  overflow: hidden;
}
