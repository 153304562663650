//font families used
$fall-back-font-family : $general-font-family;

// Typekit fonts variation
// "proxima-nova-n4", "proxima-nova", Helvetica,Arial,sans-serif;
// "museo-sans-n3", "museo-sans", Helvetica,Arial,sans-serif;
// proxima-nova: 300,400,600
// museo-sans: 300,500,700 #museo-sans-n7 not in used.

// Google fonts variation
// Signika:400,300,600,700
// Roboto+Condensed:300,400
// Open+Sans:300,400,600,700,800
// Montserrat:400,700 # not in used.
// Khula:400,300,600,700
// Work+Sans:300,400,500,600


@function calc-lineheight ($fontsize){
  $tempfont: $fontsize * $base-font-size-px;
  $lineheight: 0;
  @if($tempfont <= 12) {
    $lineheight: ($tempfont + 4)/$base-font-size-px;
    @return $lineheight;
  }
  @elseif($tempfont >= 13 and $tempfont <= 24) {
    $lineheight: ($tempfont + 6)/$base-font-size-px;
    @return $lineheight;
  }
  @elseif($tempfont >= 25 and $tempfont <= 36) {
    $lineheight: ($tempfont + 8)/$base-font-size-px;
    @return $lineheight;
  }
  @elseif($tempfont >= 37 and $tempfont <= 50) {
    $lineheight: ($tempfont + 10)/$base-font-size-px;
    @return $lineheight;
  }
  @else {
    $lineheight: ($tempfont + 12)/$base-font-size-px;
    @return $lineheight;
  }
}

@function calc-font-weight($fw-get, $fw-list, $fw-default) {
  @each $current-fw in $fw-list {
    @if ($current-fw == $fw-get) {
      @return $current-fw;
    }
  }
  @return $fw-default;
}


@mixin font-proxima-nova($font-size: 1, $line-height: 0, $fw: 4){
  $line-height-calc: 0;
  $fw-list: 3 4 6;
  $fw: calc-font-weight($fw, $fw-list, 4);

  @if($line-height == 0) {
    $line-height-calc: calc-lineheight($font-size);
  } @else {
    $line-height-calc: $line-height;
  }
  font-style: normal;
  font-weight: #{$fw * 100};
  font-size: #{$font-size + 0rem};
  line-height: #{$line-height-calc + 0rem};
  font-family: "proxima-nova", $fall-back-font-family;
}

@mixin font-museo-sans($font-size: 1, $line-height: 0, $fw: 5){
  $line-height-calc: 0;
  $fw-list: 3 5 7;
  $fw: calc-font-weight($fw, $fw-list, 5);
  @if($line-height == 0) {
    $line-height-calc: calc-lineheight($font-size);
  } @else {
    $line-height-calc: $line-height;
  }
  font-style: normal;
  font-weight: #{$fw * 100};
  font-size: #{$font-size + 0rem};
  line-height: #{$line-height-calc + 0rem};
  font-family: "museo-sans", $fall-back-font-family;
}

@mixin font-khula($font-size: 1, $line-height: 0, $fw: 4){
  $line-height-calc: 0;
  $fw-list: 3 4 6 7;
  $fw: calc-font-weight($fw, $fw-list, 4);
  @if($line-height == 0) {
    $line-height-calc: calc-lineheight($font-size);
  } @else {
    $line-height-calc: $line-height;
  }
  font-style: normal;
  font-weight: #{$fw * 100};
  font-size: #{$font-size + 0rem};
  line-height: #{$line-height-calc + 0rem};
  font-family: "Khula", $fall-back-font-family;
}

@mixin fontSize-lineHeight($font-size: 1, $line-height: 0, $fw: 0){
  $line-height-calc: 0;
  @if($line-height == 0) {
    $line-height-calc: calc-lineheight($font-size);
  } @else {
    $line-height-calc: $line-height;
  }
  font-size: $font-size + 0rem;
  line-height: $line-height-calc + 0rem;

  @if($fw != 0) {
    font-weight: #{$fw * 100};
  }
}

@mixin font-AveriaSans($font-size: 1, $line-height: 0, $fw: 5){
  $line-height-calc: 0;
  $fw-list: 3 5 7;
  $fw: calc-font-weight($fw, $fw-list, 5);
  @if($line-height == 0) {
    $line-height-calc: calc-lineheight($font-size);
  } @else {
    $line-height-calc: $line-height;
  }
  font-style: normal;
  font-weight: #{$fw * 100};
  font-size: #{$font-size + 0rem};
  line-height: #{$line-height-calc + 0rem};
  font-family: "AveriaSans", $fall-back-font-family;
}

@mixin font-QuickSand($font-size: 1, $line-height: 0, $fw: 5, $unit: rem) {
  $line-height-calc: 0;
  $fw-list: 3 5 7;
  $fw: calc-font-weight($fw, $fw-list, 5);

  @if $line-height ==0 {
    $line-height-calc: calc-lineheight($font-size); // Calculate line-height if not provided
  }

  @else {
    $line-height-calc: $line-height;
  }

  font-style: normal;
  font-weight: #{$fw * 100};
  font-size: #{$font-size + $unit}; // Use the specified unit for font-size
  line-height: #{$line-height-calc + $unit}; // Use the specified unit for line-height
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Quicksand", $fall-back-font-family;
}

@mixin font-Overlock($font-size: 1, $line-height: 0, $fw: 4){
  $line-height-calc: 0;
  $fw-list: 4 7 9;
  $fw: calc-font-weight($fw, $fw-list, 4);
  @if($line-height == 0) {
    $line-height-calc: calc-lineheight($font-size);
  } @else {
    $line-height-calc: $line-height;
  }
  font-style: normal;
  font-weight: #{$fw * 100};
  font-size: #{$font-size + 0rem};
  line-height: #{$line-height-calc + 0rem};
  font-family: "Overlock", cursive;
}

@mixin font-Graphie($font-size: 1, $line-height: 0, $fw: 5, $unit: rem){
  $line-height-calc: 0;
  $fw-list: 5 6 7;
  $fw: calc-font-weight($fw, $fw-list, 5);
  @if($line-height == 0) {
    $line-height-calc: calc-lineheight($font-size);
  } @else {
    $line-height-calc: $line-height;
  }
  font-style: normal;
  font-weight: #{$fw * 100};
  font-size: #{$font-size + $unit}; // Use the specified unit for font-size
  line-height: #{$line-height-calc + $unit}; // Use the specified unit for line-height
  font-family: "Graphie", $fall-back-font-family;
}
