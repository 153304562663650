@import '../mixins/flex';
@import '../mixins/positioning';
// All new styles goes in this file

.header-mobiletab-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1a1a1a;
  opacity: 0.6;
  z-index: 99;
}

.header-mobiletab-header-wrapper {
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 7.8rem;
}

.header-mobiletab-burger-line {
  background-color: #08064b;
  border-radius: 0.2rem;
  height: 0.4rem;
}

.header-mobiletab-burger-line1 {
  width: 1.8rem;
  margin-bottom: 0.3rem;
}

.header-mobiletab-burger-line2 {
  width: 2.6rem;
  margin-bottom: 0.3rem;
}

.header-mobiletab-burger-line3 {
  width: 1rem;
  margin-bottom: 0;
}

.header-mobiletab-wrapper {
  @include flex-center;
  height: 7.8rem;
  background-color: #ffffff;
  padding: 0 2rem;
  border: solid 0.1rem #DCDCEA;
  position: relative;
  img {
    width: 17.3rem;
  }
  & > .hamburger, & > .ipad-signup {
    @include position-top-center;
  }
  & > .hamburger {
    left: 4%;
    padding-left: 1.9rem;
    &.active:after {
      @include custom-position-top-left(-1.5rem, 1rem);
      content: "";
      width: 4.8rem;
      height: 4.8rem;
      background-color: #EDEDF9;
      border-radius: 0.8rem;
      z-index: -1;
    }
  }
  @media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
    & .hamburger {
      left: 3%;
    }
  }
  @media screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min) {
    & .hamburger {
      left: 1%;
    }
  }
  & > .ipad-signup {
    right: 0;
    margin-right: 2.4rem;
  }
  @media screen and (min-width:$screen-md-min) and (max-width:1279px){
    > .hamburger{
      left:0;
    }
  }
}

.header-curriculum-menu-wrapper-white-theme {
  border: solid 0.1rem #DCDCEA;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.25);
  .edu-link {
    cursor: pointer;
    border-bottom: solid 0.1rem #EDEDF9;
    text-align: left;
    .edu-link-a {
      background-color: #ffffff;
      color: #1A1A1A;
      padding: 1rem 2.2rem;
      display: inline-block;
      width: 23rem;
      &:hover {
        background-color: #EDEDF9;
        text-decoration: none;
        color: #4f52c3;
      }
      
    }
  }
    @media screen and (max-width:1279px){
       border:none;
       box-shadow: none;
       .edu-link{
         cursor: pointer;
         border:none;
         .edu-link-a{
           &:hover{
             color:unset;
           background-color: unset;
         }
        }
       }
    }
}

.header-mobiletab-menu {
  ul {
    background-color: #ffffff;
    text-align: center;
    li {
      font-weight: 600;
      position: relative;
      color: #1A1A1A;
      text-align: left;
      &:nth-child(1) {
        border-top: solid 0.1rem #DCDCEA;
      }
      .header-mobiletab-a {
        color: #1A1A1A;
        width: 100%;
        height: 100%;
        display: block;
        padding-left: 3.8rem;
        padding-top:2rem;
        padding-bottom: 1.8rem;
        text-decoration: none;
      }
      .header-mobiletab-a-login{
         width:300px;
         border:2px solid #4F52C3;
         border-radius:3.2rem;
         text-align:center;
         @include font-Graphie(1.6,2,6);
      }
      & .mobile-arrow {
        @include custom-position-top-left(1.3rem, 15%);
        height: 1rem;
        width: 1rem;
        box-shadow: 0.3rem 0.3rem 0 0 #000;
        transform: rotate(45deg);
      }
      & .dropdown {
        padding-left: 3.8rem;
      }
      &.closed{
        
        padding-top:2rem;
        padding-bottom: 1.8rem;
      }
      &.open {
        background-color: #EDEDF9;
        color:#4F52C3;
        padding-top:2rem;
        ul {
          display: block;
          position: relative;
          left: auto;
          border-top: solid 0.1rem #DCDCEA;
          top:1.4rem;
          margin-bottom: 1.4rem;
          li {
            .edu-link-a {
              width: auto;
              background-color: #ffffff;
              display: block;
              color: #1A1A1A;
              padding: 0;
              padding-left: 6.2rem;
            }
          }
          
        }
        & .mobile-arrow {
          top: 3.5rem;
          transform: rotate(-135deg);
          color:#4F52C3;
        }
      }
      @media screen and (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
        & .mobile-arrow {
          left: 18%;
        }
      }
  
      @media screen and (min-width: $screen-xs-min) and (max-width: 1279px) {
        & .mobile-arrow {
          top:2.3rem;
          right: 6%;
          left: auto;
        }
      }
    }
    #btn{
      display: grid;
      place-content: center;
      padding-top:2rem;
      padding-bottom:3.2rem;
      .header-mobiletab-a{
        width:300px;
        border:2px solid #4F52C3;
        border-radius:3.2rem;
        text-align:center;
        @include font-Graphie(1.6,2,6);
        padding:1.2rem 12rem;

      }
    }
  }
}

.header-mobiletab-container{
  display: flex;
  .header-mobiletab-login-btn {

      .header-mobiletab-login-a {
        margin-right: 2.4rem;
        @include font-Graphie(1.8, 2.4, 6);
        border: 0.2rem solid #4F52C3;
        border-radius: 3.2rem;
        padding:1.2rem 2.8rem;
        &:hover {
          &:after {
            width: 0%;
          }
          border-color: #141975;
        }
        text-align: center;
        display: inline-block;
        position: relative;
        cursor: pointer;
        color: #090A4A;
        text-decoration: none;
        
    }
    &:last-of-type{
      .header-mobiletab-login-a{
        background-color: #090A4A;
        color:#ffffff;
        border: 0.2rem solid #090A4A;
        &:hover {
          background: #141975;
        }
        &.tutoring-signup-btn {
          border-color:#4f52c3;
          color: #090a4a;
          background-color: #ffffff;
          &:hover {
            border-color: #141975;
          }
        }
      }
    }  
  }
}

.header-mobiletab-signup-link-mobile {
  border-radius: 3rem;
  text-align: center;
  padding:1.2rem 2.4rem;
  font-weight: 600;
  background: #090A4A;
    .header-mobiletab-signup-link-mobile-a {
      text-decoration: none;
      color: #ffffff;
      width: 100%;
      height: 100%;
      display: block;
    }
    &:hover {
      background: #141975;
    }
}

.header-desktop-login-link-signup{
  padding: 1rem 2.8rem;
  border-radius: 3.2rem;
  text-align: center;
  font-weight: 600;
  height: 4.8rem;
  cursor: pointer;
}

.header-desktop-theme-true {
  background: #ffffff;
  position: fixed !important;
  top: 0;
  width: 100% ;
  transform: translateY(0);
  transition: transform 0.5s;
}

.whychosedesktop {
  .paint-wrapper{
    .paint-wrapper-img {
      @include custom-position-top-right(-26%, 0);
      display: block;
      z-index: -1;
      width: 42%;
      max-width: 60.5rem;
      max-height: 28rem;
    }
  }
  .whychosedesktop-content-wrapper {
    display: flex;
    justify-content: space-between;
    .content-info-wrapper{
      width: 42%;
      .content-info{
        margin-top: 4rem;
        transition: color 1.5s;
        cursor: pointer;
        .info-title {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 1rem;
          position: relative;
          .info-bullet {
            @include custom-position-top-left(50%, -6rem);
            height: 0.8rem;
            width: 0.8rem;
            border-radius: 50%;
            background: #1a1a1a;
            transition: transform 1.5s;
          }
          .bullet-transform {
            transform: scale(2);
            background: #4f52c3;
          }
        }
        .info-desc {
          font-size: 1.6rem;
          line-height: 1.5;
        }
      }
      .color-indigo {
      color: #4f52c3;
      }
      .color-black {
      color: #1a1a1a;
      }
    }
    .content-image-wrapper {
      width: 50%;
      div {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .content-image {
        height: 41rem;
        max-width: 100%;
        position: absolute;
        bottom: 0;
        transition: transform 1.5s;
      }
      .translate-0 {
        transform: translateX(0);
      }
      .translate-200 {
        transform: translateX(200%);
      }
      .parent-tranform-50{
        transform: translateX(50%);
      }
      .parent-tranform-500{
        transform: translateX(500%);
      }
    }
  }
}

.learningexp-content-box {
  text-align: center;
  color: #1A1A1A;
  max-width: 32rem;
  img {
    width: 10rem;
    height: 10rem;
    margin: auto;
  }
  .content-title {
    font-size: 2rem;
    margin-top: 2rem;
    font-weight: 600;
  }
  .content-subtitle{
    font-size: 1.6rem;
    margin-top: 2rem;
  }
}

.header-desktop-theme-true-login-link-signup-btn-blue {
  background: #090A4A;
  color: #ffffff;
  border: 0.2rem solid transparent;
  @include font-Graphie(1.8, 2.4, 6);
  .header-desktop-login-link-signup-btn-blue-a {
    text-decoration: none;
    color: unset;
    width: 100%;
    height: 100%;
    display: block;
  }
  &:hover {
    background: #141975;
  }
  &.tutoring-signup-btn {
    border-color:#4f52c3;
    color: #090a4a;
    background-color: #ffffff;
    &:hover {
      border-color: #141975;
    }
  }
}

.header-desktop-nav-wrapper {
  height: 8rem;
  position: absolute;
  width: 100%;
  z-index: 100;
  transform: translate(0);
  background-color: #ffffff;
  border-bottom: 0.2rem solid #DCDCEA;
  & > .content-container {
    @include custom-flex(space-between, center);
    height: 100%;
    margin: auto;
    position: relative;
    .logo-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      .logo-splashlearn {
        width: 17.3rem;
      }
      &:before {
        width: 50rem;
        content: "";
        @include custom-position-top-left(0, -14.4rem);
        height: 100%;
        z-index: -1;
        background: url('images/loggedout/home/logo-container.png') top
    left/100% 100% no-repeat;

      }
    }
  }
}

.header-desktop-nav-wrapper-hide {
  transform: translateY(-100%);
  transition: transform 0.5s;
}

.header-desktop-login-link-ul {
  @include flex-vertical-center;
  text-decoration: none;
  .login-link-signup {
    padding: 1.2rem 2rem;
    border-radius: 2.7rem;
    text-align: center;
    font-weight: 600;
  }
  .login-btn {
    align-items: center;
    text-decoration: none;
    &.dark-theme {
      margin-right: 2.4rem;
      display: inline-block;
      position: relative;
      cursor: pointer;
      color: #1A1A1A;
      padding-bottom: 3rem;
      margin-bottom: -3rem;
    }
    .home-path {
        margin-right: 1rem;
        @include font-Graphie(1.8, 2.4, 6);
        border: 0.2rem solid #4F52C3;
        border-radius: 3.2rem;
        height: 4.8rem;
        &:hover {
          &:after {
            width: 0%;
          }
          border-color: #141975;
        }
        text-align: center;
        display: inline-block;
        position: relative;
        cursor: pointer;
        color: #090A4A;
        padding: 1rem 2.8rem;
    }
  }
}

.header-desktop-nav-link-wrapper{
  text-align: center;
  .nav-link {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 0 1rem 3rem;
    margin-bottom: -3rem;
    @include font-Graphie(1.6, 1.92, 6);
    &:after {
      content: "";
      width: 0;
      height: 0.6rem;
      display: block;
      @include position-left-center;
      bottom: -0.1rem;
      transition: width .3s ease, background-color .3s ease;
    }
    &:not(:last-of-type) {
      margin-right: 1.6rem;
    }
    & > span {
      margin-right: 1rem;
    }
    & .arrow {
      height: 0.8rem;
      width: 0.8rem;
      @include custom-position-top-right(0.4rem, -8%);
      border-bottom: 0.1rem solid #090a4a;
      border-right: 0.1rem solid #090a4a;
      transform: rotate(45deg);
    }
    &:hover {
      & > .nav-link-a {
        text-decoration: none;
        text-align: left;
      }
      & > ul {
        display: block;
      }
      & .arrow {
        top: 1rem;
        transform: rotate(-135deg);
      }
    }
    &.educator {
      &:hover {
        &:after {
          width: 102%;
          left: 54%;
        }
      }
    }
  }
  .deep-dark {
    color: #1A1A1A;
    & > a {
      color :#1A1A1A;
    }
    &:hover {
      &:after {
        width: 100%;
        background-color: #141975;
      }
    }
  }
  .active {
      &:after {
        width: 100%;
        background-color: #141975;
    }
  }
}

.header-curriculum-menu-wrapper {
  @include custom-position-top-left(100%, -2rem);
  display: none;
  z-index: 1;
}

.whychosemobile-content-wrapper{
  text-align: center;
  width: 100%;
  .infoWrapper{
    margin: 5rem auto 0;
    img{
      max-width: 100%;
      height: 35.3rem;
      @media screen and (max-width: $screen-sm-min) {
        height: 24.7rem;
      }
    }
    .info{
      width: 27rem;
      margin: auto;
        @media screen and (max-width: $screen-sm-min) {
          width: 34rem;
        }
      .infoTitle{
        font-size: 2rem;
        font-weight: 600;
        margin: 2.6rem auto 1.5rem;
        position: relative;
      }
      .infoDesc{
        font-size: 1.6rem;
        line-height: 1.5;
      }
    }
  }
}


.loggedout-home-top-section {
  height : 61rem;
  width: 100%;
  background-color: #090A4A ;
  background-position: 78% 80%;
  position: relative;
  padding-top: 22rem;
  @media screen and (max-width: $screen-md-min) {
    height:76rem;
    padding-top: 20rem;
    background: url('images/loggedout/home/mobile_image_banner.png') top left/cover no-repeat;
    background-color: #090A4A;
    background-size:  39rem 27rem;
    background-position: 50% 98%;
  }
  @media screen and (max-width:422px){
    background-position: 50% 104%;
    height:78rem;
  }
}

.modular-section-3-wrapper {
  width: 133rem;
  margin: 0 auto;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  .head-text {
    padding-top: 6.5rem;
    color: #1A1A1A;
    @include font-Graphie(3.6, 4.2, 7);
    text-align: center;
    padding-bottom: 2rem;
  }
  .sub-head-text {
    color: #1A1A1A;
    font-size: 2rem;
    line-height: 2.8rem;
    text-align: center;
    padding: 0 28rem;
  }
  .img-wrap {
    padding: 0 0 3.2rem;
    > img {
      max-width: 100%;
    }
  }
  .sub-head-text1{
    color: #1A1A1A;
    font-size: 2rem;
    line-height: 2.8rem;
    text-align: center;
    padding-bottom: 1.1rem;
    > span {
      background-color: #A5F9E8;
    }
  }
  @media screen and (max-width:$screen-md-min) {
    padding:0 1.7rem 0 1.8rem;
    width: 100%;
    .head-text{
      padding-top:6rem;
      @include font-Graphie(2.8, 3.4, 7);
    }
    .sub-head-text{
      @include font-Graphie(1.6, 2.4, 4);
      padding:2.6rem 0 3.5rem 0;
    }
    .img-wrap{
      display: grid;
      place-items:center;
      img{
        width:44rem;

      }
      margin-bottom:3.2rem;
    }
    .sub-head-text1{
      @include font-Graphie(1.6, 2.4, 4);
      color:#363639;
      padding-bottom:0.8rem;
      &:last-child{
       padding-bottom:0; 
      }
      >span{
        background-color:transparent;
        font-weight: 700;
      }

    }
  }
  @media screen and (min-width:$screen-md-min) and (max-width:1279px) {

    padding:0 2.5rem;
    width:100%;
      .head-text{
        @include font-Graphie(3,4,7);
      }
      .sub-head-text{
        @include font-Graphie(1.6,2.4,4);
        padding:0 0 5rem 0;
      }
      .sub-head-text1{
         @include font-Graphie(1.6,2.4,4);
         span{
           background-color: transparent;
         }
      }
      


  }
}


.modular-section-2-wrapper {
  background-color: #EDEDF9;
  padding: 7rem 10rem;
  .head-text {
    padding-top: 2rem;
    color: #1A1A1A;
    @include font-Graphie(3.6, 4.2, 7);
    text-align: center;
    padding-bottom: 2rem;
  }
  .sub-head-text {
    color: #1A1A1A;
    font-size: 2rem;
    line-height: 2.2rem;
    text-align: center;
    padding: 0 24rem 5.4rem;
  }
  .section-2-ul-list {
    @include custom-flex(space-between, flex-start);
    .section-2-li-list-item {
      text-align: center;
      color: #1A1A1A;
      width: 25%;
      padding: 0 1.5rem;s
      .section-2-img {
        width: 17.8rem;
        height: 17rem;
      }
      p {
        @include font-Graphie(2, 3.2, 6);
        padding-bottom: 0.4rem;
      }
      span {
        @include font-Graphie(1.6, 2.2, 4);
      }
    }
  }
  .section-2-primary-cta-button {
    width: fit-content;
    color: #ffffff;
    background-color: #090A4A;
    @include font-Graphie(1.8, 2.4, 6);
    padding: 1.6rem 4.5rem;
    border-radius: 3.2rem;
    margin: 3.6rem auto 0;
    cursor: pointer;
    &:hover {
      background-color: #141975;
    }
    a {
      text-decoration: none;
      color: #ffffff;
    }
  }
  @media  screen and (max-width: $screen-md-min) { 
    padding: 5rem 1.7rem 7rem 1.7rem;

    .head-text{
      @include font-Graphie(2.8,3.4,7);
    }
    .sub-head-text{
      @include font-Graphie(1.6,2.4,4);
      padding:0;
    }
    .section-2-ul-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      .section-2-li-list-item{
        width:100%;
        p{
          @include font-Graphie(2,4,6);
        }
        span{
          @include font-Graphie(1.4,2,4);
        }

      }
    }
    .section-2-primary-cta-button{
      width:30rem;
      height:4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding:0.8rem 0;
    }
  }
  @media  screen and (min-width: $screen-md-min) and (max-width:1279px) {
    padding:10rem 2.5rem;
    padding-right:2.5rem;
    padding-left:2.5rem;
    .head-text{
      @include font-Graphie(3,4,7);
    }
      .sub-head-text{
        @include font-Graphie(1.6,2.4,4);
        padding:0 20rem 5rem;
      }
    .section-2-ul-list{
      .section-2-li-list-item{
        p{
          @include font-Graphie(1.8,2.4,6);
        }
        span{
          @include font-Graphie(1.2,1.8,4);
        }
      }
    }
  }
}

.modular-section-1-wrapper {
  background-color: #ffffff;
  padding-bottom: 12rem;
  .head-text {
    padding-top: 4.5rem;
    color: #1A1A1A;
    @include font-Graphie(3.6, 4.2, 7);
    text-align: center;
    padding-bottom: 1rem;
    .scrolling-txt-wrap {
      position: relative;
      display: inline-block;
      height: 3.2rem;
      width: 16rem;
      top: 0.4rem;
      .scrolling-txt {
        @keyframes scrollUp {
          0% {
            transform: translateY(0);
          }

          11% {
            transform: translateY(-25%);
          }

          33% {
            transform: translateY(-25%);
          }
          45% {
            transform: translateY(-50%);
          }

          66% {
            transform: translateY(-50%);
          }
          77% {
            transform: translateY(-75%);
          }

          100% {
            transform: translateY(-75%);
          }
        }
        position: absolute;
        top: 0;
        width: 100%;
        height: 3.2rem;
        font-size: 3rem;
        color: #1A1A1A;

        .box {
          height: 4.1rem;
          overflow: hidden;
          position: relative;
        }
        ul {
          float: left;
          margin: 0;
          padding: 0;
          -webkit-animation: scrollUp 9s ease-in-out infinite normal;
          animation: scrollUp 9s ease-in-out infinite normal;
          > li {
            opacity: 1;
            height: 5.2rem;
            line-height: 3.2rem;
            padding: 0 0 2rem;
            list-style: none;
            text-align: left;
          }
        }
        &.section-top {
          top: -3.2rem;
          color: #FD825D;
          display: block !important;
          &:after {
            z-index: 1;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 4.1rem;
            content: '';
            box-shadow: 0 2.6rem 0 0 #fff inset;
          }
        }
        &.section-bottom {
          top: 3.2rem;
          color: #B746E6;
          display: block !important;
          &:after {
            z-index: 1;
            position: absolute;
            height: 4.1rem;
            top: 0;
            left: 0;
            right: 0;
            content: '';
            box-shadow: 0 -2rem 0 0 #fff inset;

          }
        }
      }
    }
  }
  .main-wrapper {
    display: flex;
    max-width: 120rem;
    flex-wrap: wrap;
    margin: auto;
    .left-img-wrap {
      flex: 0 0 60%;
      text-align: right;
      padding: 5rem 1rem 0 0 ;
      position: relative;
      .section-1-img {
        width: 100%;
      }
    }
    .right-text-wrap {
      padding-left: 1rem;
      flex: 0 0 40%;
      padding-top: 5rem;
      .ul-list {
        .li-list-item {
          color: #1A1A1A;
          @include font-Graphie(1.6, 2.2, 4);
          padding: 1rem 0 1.6rem;
          > span {
            background-color: #A5F9E8;
          }
        }
      }
      .bottom-txt {
        color: #1A1A1A;
        @include font-Graphie(2, 2.6, 6);
        padding-top: 5.1rem;
        > span {
          background-color: #A5F9E8;
        }
      }
    }
  }
  @media screen and (max-width: $screen-md-min){
    padding:10rem 1.7rem 6rem 1.7rem;
    .head-text{
      font-size:2.8rem;
      line-height: 3.2rem;
      padding-top:0;
      display:grid;
      place-items:center;
      .scrolling-txt-wrap{
        margin-top:0.6rem;
        .scrolling-txt{
          margin-left:2rem;
          &:not(:nth-child(2)){
            display:none !important;
          }
        }
      }
    }
    
    .main-wrapper{
      flex-direction: column;
      flex-wrap: unset;
      .left-img-wrap{
        padding:3.6rem 0 4.5rem 0;
        flex: 0 0 100%;
        text-align: center;
        max-width: 42rem;
        align-self:center;
        img{
          width:100%;
        }
      }
      .right-text-wrap{
        display: grid;
        place-items: center;
        padding:0;
        .ul-list{
          .li-list-item{
          @include font-Graphie(1.4,2,4);
          padding:0 0 1.2rem 0;
          &:first-child{
            padding-top:3.3rem;
          }
        }
      }
      .bottom-txt{
        @include font-Graphie(1.6,2.4,6);
        padding-top:1.2rem;
        span{
          background-color: #ffffff;
        }
      }
      }
    }
  }
  @media screen and (min-width: $screen-md-min) and (max-width:1279px){
    padding:10rem 2.4rem 0 2.4rem;
    .head-text{
      padding-top:0;
      @include font-Graphie(3,4,7);
    }
    .main-wrapper{
      .right-text-wrap{
        .ul-list{
          .li-list-item{
            @include font-Graphie(1.4,2,4);
          }
        }
        .bottom-txt{
          @include font-Graphie(1.6,2.4,6);
          padding-top: 2rem;
          span{
            background-color: transparent;
          }
        }
      }
    }
  }
}

.universe-learning-card-wrapper {
  margin-top: 1.6rem;
  transition: all 0.4s ease-in-out;
  &:hover {
    transform: scale(1.04);
  }
  .card {
      height: 100%;
      margin: auto 1.5rem;
      border: solid 0.1rem #dedede;
      overflow: hidden;
      border-radius: 1.3rem;
      padding: 1rem 1rem 2rem;
      text-align: left;
      cursor: pointer;
      background-color: #ffffff;
      .card-image {
        height: 23rem;
        background: lightgray;
        width: 100%;
        border-radius: 1rem;
        overflow: hidden;
        .universe-learning-img {
          width: 100%;
          height: 100%;
        }
      }
    .card-title {
      font-size: 2.9rem;
      color: #1a1a1a;
      margin-top: 2rem;
      font-weight: 700;
    }
    .card-desc-group {
      gap: 1rem;
      display: flex;
      flex-wrap: wrap; 
      height: 6rem;
      .card-desc {
        padding: .5rem 1rem;
        border-radius: 0.6rem;
        width: fit-content;
        background-color: $curriculamGroupLinkLightBlue;
        color: #2284E2;
        text-decoration: none;
        height: 2.7rem;
        @include font-Graphie(1.6,1.7,6);
      }
    }
    .see-link {
      text-decoration: underline;
      margin-top: 1rem;
      display: block;
      color: #4f52c3;
    }
  }
  @media screen and (max-width:$screen-md-min){
    margin-top:2.4rem;
    .card{
      &:not(img){
        padding-left:1.2rem;
      }
      margin:auto 1.6rem;
      padding:0.4rem 0.4rem 2rem 0.4rem;
      .card-image{
        height:18rem;
      }
      .card-title{
        @include font-Graphie(1.8,3,7);
        padding-top:1rem;
      }
      .card-desc{
        @include font-Graphie(1.4,2.4,4);
        color:#6C6C73;
        padding-top:0.4rem;
      }
      .see-link{
        @include font-Graphie(1.4,2.4,4);
        margin-top:0.2rem;
      }
    }

  }
}

@media screen and (max-width: ($screen-md-min)){
  .homepage-video-variant-gif-container {
    display: none;
  }
}

.homepage-video-variant-gif-container {
  position: relative;
  width: 57.5rem;
  height: 55.2rem;
  left: 50%;
  transform: translate(25%, -7%);
  bottom: 1rem;
  .static-image-wrapper {
    position: relative;
    width: 54rem;
    height: 45.2rem;
    right: 7rem;
    bottom: 4rem;
    .home-banner {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
    }
  }
  @media screen and (min-width:($screen-md-min+1)) and (max-width:1279px){
    
    position: relative;
    width: 48.5rem;
    height: 38.2rem;
    left: 64%;
    bottom: 1rem;
  }
  @media screen and (min-width:($screen-md-min+1)) and (max-width:870px){
    .static-image-wrapper{
      position: relative;
      width: 42rem;
      height: 38.2rem;
      right: 54%;
      bottom: 1rem;
      margin: 0 auto;
    }
  }
  @media screen and (min-width:871px) and (max-width:1279px){
    .static-image-wrapper{
      width:48rem;
      height: 38.2rem;
      right: 54%;
      bottom: 3rem;
      margin: 0 auto;

    }
    
  }
}

.homepage-video-variant-video-overlay { //need to remove this code after sometimes, because, now we have a file for this code 'web-ui/app/css/_vimeo_video_modal.scss'
  position: fixed;
  top: 0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 100;
  .video-container {
    position:relative;
    @include position-top-left-center;
    height: 56.4rem;
    width: 64rem;
    text-align: center;
    iframe {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: $screen-sm-min) {
      width: 100%;
    }
    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-lg-min) and (orientation: landscape) {
      height: 80%;
    }
    .cross-btn {
      @include custom-position-top-right(-0.8rem, 0);
      cursor: pointer;
      height: 2.6rem;
      width: 2.6rem;
      background-color: #ffffff;
      border-radius: 50%;
      img {
        box-sizing: border-box;
        width: 100%;
        padding: .6rem;
      }
      @media screen and (max-width: $screen-sm-min) {
        top: 6rem;
      }
      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-lg-min) and (orientation: landscape) {
        right: 4rem;
        top: 0;
      }
    }
  }
  .visible {
    visibility: visible;
  }
  .hidden {
    visibility: hidden;
  }
}

.universe-learning-popup-content {
  text-align: center;
  margin: 8.6rem;
  & > .signup-cards {
    @include flex-horizontal-center;
    margin-top: 3.6rem;
    .signup-a {
      &:nth-child(2) {
        margin: 0 0 0 8rem;
      }
    }
   // iphones heights behave differently thats why taking device heights into consideration
    @media (max-width: $screen-lg-min) and (min-height: 56.2rem),
    (min-device-width: 32rem) and (max-device-height: 56.8rem) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2),
    (min-device-width: 37.5rem) and (max-device-height: 66.7rem) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
      flex-direction: column;
      align-items: center;
      .signup-a {
        &:nth-child(2) {
          margin: 4rem 0 0 0;
        }
      }
    }
  }
  @media screen and (max-width: $screen-lg-min) {
    margin: 4.4rem;
  }
  // @media  (max-height: 56.2rem), (max-width: $screen-sm-min){
  //   @include position-top-left-center;
  //   margin: 0;
  //   width: 100%;
  // }
}

.trysplashlearn-freebox {
  padding: 4rem 10rem 13rem;
  background-color: #ffffff;
  .head-text {
    color: #1A1A1A;
    @include font-Graphie(3.6, 4.2, 7);
    text-align: center;
    padding-bottom: 2rem;
    @media screen and (max-width: $screen-sm-min) {
      padding-bottom: 5rem;
    }
  }
  
  .trysplashlearn-main-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    @media screen and (max-width: $screen-md-min) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .left-img-wrap {
      padding-right: 4.5rem;
      flex: 0 0 50%;
      text-align: right;
      //added new from mastere
      img {
        height: 100%;
      }
      @media screen and (max-width: 768px) {
        flex: unset;
        padding: unset;
        margin-bottom: 2rem;
        img {
          width: 100%;
          height: unset;
        }
      }
    }
    .right-text-wrap {
      @include flex-horizontal-center;
      padding-left: 4.5rem;
      flex: 0 0 50%;
      flex-direction: column;
      .sub-heading {
        @include font-Graphie(2.6, 3.2, 7);
        margin-bottom: 1rem;
        &.heading-1 {
          color: #B746E6;
        }
        &.heading-2 {
          color: #2284E2;
        }
      }
      p {
        @include font-Graphie(2, 2.8, 6);
        margin-bottom: 2rem;
      }
      .ul-list {
        .li-list-item {
          color: #1A1A1A;
          margin-bottom: 1.2rem;
          .tick-icon {
            width: 1.8rem;
            display: inline !important;
          }
          span {
            @include font-Graphie(2, 3.2, 4);
            padding-left: 1.5rem;
          }
        }
      }
      .trysplashlearn-primary-cta-button {
        @include font-Graphie(1.8, 2.4, 6);
        width: fit-content;
        color: #ffffff;
        background-color: #090A4A;
        padding: 1.6rem 4.5rem;
        border-radius: 3.2rem;
        margin-top: 3rem;
        cursor: pointer;
        &:hover {
          background-color: #141975;
        }
      }
      @media screen and (max-width: $screen-md-min) {
        @include flex-center;
        width: 100%;
        flex: unset;
        padding: unset;
      }
    }

  }
  @media screen and (max-width: $screen-md-min) {
    padding: 12rem 1.7rem 10rem 1.7rem;
    box-shadow: none;
    .head-text{
      @include font-Graphie(2.8, 3.4, 7);
      padding:0;
      margin-bottom: 4rem;
    }
    
    .trysplashlearn-main-wrapper{
     flex-direction: column;
     align-items: center;
      .left-img-wrap{
        padding:0;
        margin-bottom: 2.8rem;
        img{
          height:22rem;
          width:34rem;
        }
      }
      .right-text-wrap{
        padding:0;
        .ul-list{
          align-self: center;
          .li-list-item{
            margin-bottom: 0.8rem;
            span{
              @include font-Graphie(1.8, 3.2, 4);
            }
          }
        }
        .trysplashlearn-primary-cta-button{
          margin-bottom:7.1rem;
          text-align: center;
          padding:0.8rem 4rem;
          @include font-Graphie(1.6, 2.4, 6);
          width:30rem;
          height:4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #090A4A;
        }
      }
    }
  }
  @media screen and (min-width:$screen-md-min) and (max-width:1279px){
    padding:4rem 2.5rem 24rem;
    .head-text{
      @include font-Graphie(3,4,7);
    }
    .trysplashlearn-main-wrapper{
      justify-content: center;
      position: relative;
      padding-bottom: 2rem;
      .left-img-wrap{
        flex:unset;
      }
      .right-text-wrap{
        flex:unset;
        .ul-list{
          .li-list-item{
            span{
              @include font-Graphie(1.8,3.2,4);
            }
          }
        }
        .trysplashlearn-primary-cta-button{
          position: absolute;
          bottom: -32%;
          left: 50%;
          transform: translate(-50%,-50%);
          width:auto;
        }
      }
    }
  }
}

.modular-stats-wrapper {
  .head-text{
    color: #1A1A1A;
    font-size: 3.6rem;
    text-align: center;
  }
  .content-wrapper {
    @include custom-flex(space-between, center);
    padding: 0 10%;
    margin: 0 auto;
    .content-box {
      text-align: center;
      p {
        &:nth-child(1) {
          color: #4f52c3;
          font-size: 1.6rem;
          margin-bottom: 0.6rem;
        }
        &:nth-child(2) {
          color: #4b5ebd;
          font-size: 3rem;
          font-weight: 700;
        }
      }
    }
  }
}

.stats-wrapper {
  display: flex;
  height: 100%;
  position: relative;
  .ellipse {
    position: absolute;
    height: 6.8rem;
    width: 6.8rem;
    right: 27.5%;
  }
  .ellipse-blue {
    @include custom-position-top-right(4.4rem, 33.4%);
    height: 2.4rem;
    width: 2.4rem;
  }
  @media screen and (max-width: $screen-xl-min) {
    & > .shape {
      display: none;
    }
  }
  .contentWapper{
    @include custom-flex(space-between, center);
    width: 80%;
    margin: 0 auto;
    @media screen and (max-width: $screen-sm-min) {
      justify-content: center;
    }
    .contentBox{
      text-align: center;
      p {
        &:nth-child(1) {
          color: #4f52c3;
          font-size: 1.6rem;
          margin-bottom: 0.6rem;
        }
        &:nth-child(2) {
          color: #4b5ebd;
          font-size: 3rem;
          font-weight: 700;
        }
      }
      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-xl-min) {
        p:nth-child(2) {
          font-size: 1.8rem;
        }
      }

    }
    @media screen and (max-width: $screen-sm-min) {
      .not-important {
        display: none;
      }
      .important {
        display: block;
      }
    }
  }
}

.signupcontainer-content-wrapper {
  
  text-align: center;
  margin-bottom: 5rem;
  color: #ffffff;
  @media screen and (min-width: $screen-md-min) {
    @include custom-position-top-left(15rem, 50%);
    transform: translate(-50%);
    width: auto;
    margin: 0;
  }
  .new-title {
    @include font-Graphie(4.6, 5, 7);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.092rem;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: left;
    @media screen and (max-width: $screen-xl-min) {
      text-align: center;
      font-size: 4rem;
    }
    em {
      font-weight: 400;
      font-style: italic;
    }
  }
  .new-subtitle {
    @media screen and (min-width:1279px){
      width:54rem;
    }

    @media screen and (max-width: $screen-xl-min) {
      text-align: center;
      font-size: 2.2rem;
    }
    text-align: left;
    font-size: 1.8rem;
    margin-top: 2rem;
  }

  .platformInfoWrapper{
    display: flex;
    margin-top: 2.9rem;
    .platformInfoLi{
      color: #A5F9E8;
      margin-right: 1rem;
    }
  }
      
      .button-wrapper {
        margin-top: 5rem;
        display: flex;
        .cta-primary-button .cta-primary-button-span{
          display: block;
          @include font-Graphie(1, 1, 4);
          padding-top:0.5rem;
          padding-bottom:1.4rem;
        }
        @media screen and (max-width: ($screen-sm-min - 1)) {
          margin-top: 3rem;
          & > .cta-primary-button {
            width: 37rem;
            height: 5.5rem;
            line-height: 5.4rem;
          }
          & > .cta-primary-button-2 {
            background: #090A4A;
            a {
              color : white;
              border: .15rem solid #4F52C3;
              border-radius: 3.4rem;
            }
          }
          
        }
        & > .cta-primary-button-2 {
          margin: 0 0 0 3rem;
          line-height: 5.2rem;
        }
        @media screen and (max-width: 1279px) {
          display: block;
          & > .cta-primary-button {
            display: block;
            margin: 0 auto;
          }
          &  > .cta-primary-button-2 {
            margin: 2rem auto 0;
          }
        }
      }

      @media screen and (min-width:($screen-md-min+1)) and (max-width:1279px){
        @include custom-position-top-left(11rem, 42%);
        transform: translate(-50%);
        width: 80% !important;
        margin: 0;
        @media screen and (min-width:992px){
           @include custom-position-top-left(11rem,45%);
        }
        @media screen and (min-width:$screen-xl-min){
          @include custom-position-top-left(11rem,50%);
        }
        .platformInfoWrapper{
          margin-bottom:2rem;
          .platformInfoLi .platformInfospan{
            @include font-Graphie(1.4, 2, 6);
          }
        }
        .new-title{
          @include font-Graphie(3,3.6,7);
          text-align: left;
        }
        .new-subtitle{
          @include font-Graphie(1.4,2,4);
          text-align: left;
          width:48rem;
        }
        .button-wrapper{
          margin-top:2.8rem;
          & > .cta-primary-button{
            margin:0;
            height:44px;
            line-height: 0;
            padding:1.2rem 0;
            a{
              padding-top:1.2rem;
            }
            .cta-primary-button-span{
              padding-top:1.8rem;
            }
          }
          & > .cta-primary-button-2{
            margin:2rem 0 0;
            line-height:0;
            a{
              display:grid;
              place-content: center;
              height:44px;
              padding:1.2rem 0;

            }
          }
        }
      }
      @media screen and (max-width: $screen-md-min) {
        display: grid;
        place-items: center;
        position: absolute;
        top: 11.7rem;
        width:100% !important;
        padding: 0 1.7rem;
        .platformInfoWrapper{
          justify-content: center;
          @include font-Graphie(1.4,2,6);
        }
        .new-title {
          margin-top:2rem;
          @include font-Graphie(3.2, 3.8, 7);
        }
        .new-subtitle{
          margin-top:1.2rem;
          @include font-Graphie(1.4, 2, 4);
        }
        .button-wrapper{
          margin:2.8rem 2rem;
          
          & > .cta-primary-button {
            width: 30rem;
            height: 4.8rem;
            line-height: 5.4rem;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                color:#090A4A;
            } 
            }
          & > .cta-primary-button-2 {
            a{
              color:#ffffff;
            }
          }
          }
        }
        
      
      @media screen and (max-width: '374px'){
        display: grid;
        place-items: center;
        position: absolute;
        top: 11.7rem;
        width:100% !important;
        padding: 0 1.7rem;
        .platformInfoWrapper{
          justify-content: center;
          @include font-Graphie(1.4,2,6);
        }
        .new-title{
          margin-top: 2rem;
          @include font-Graphie(3,3.6,7);
        }
        .new-subtitle{
          width:28.8rem;
          // line-clamp: 2;
          margin-top: 1.2rem;
          @include font-Graphie(1.2,1.8.4);
        }
        .button-wrapper{
          margin-top:2.4rem;
          & > .cta-primary-button{
            width: 28.8rem;
            height: 4.8rem;
            a{
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      
    }

.modular-section-4-wrapper {
  width: 121rem;
  margin: 0 auto;
  padding-top: 4rem;
  .head-text {
    color: #1A1A1A;
    @include font-Graphie(3.6, 4.2, 7);
    text-align: center;
    padding-bottom: 2rem;
  }
  .section-4-ul-list {
    @include custom-flex(center, flex-start);
    .section-4-li-list-item {
      text-align: center;
      color: #1A1A1A;
      width: 33%;
      padding: 0 1.5rem;s
      .section-4-img {
        width: 30rem;
        height: 21.5rem;
      }
      .section-4-p {
        @include font-Graphie(2, 2.4, 6);
        padding: 2.8rem 0 0.7rem;
      }
      .section-4-span {
        @include font-Graphie(1.6, 2.2, 4);
      }
    }
  }
}

.modular-section-0-wrapper {
  .head-text {
    color: #1A1A1A;
    @include font-Graphie(3.6, 4.2, 7);
    text-align: center;
    padding-bottom: 1rem;
  }
  .section-0-ul-list {
    display: flex;
    justify-content: space-evenly;
    padding: 3.1rem 7rem;
    .section-0-li-list-item {
      color: #1A1A1A;
      font-size: 1.6rem;
      line-height: 2.2rem;
      padding: 1rem 0 1.6rem;
      min-width: 33%;
      text-align: center;
      border-right: 0.2rem solid #DCDCEA;
      &:last-child {
        border: none;
      }
      .section-0-span {
        color: #363639;
        font-size: 1.6rem;
        line-height: 2.8rem;
      }
      .section-0-p {
        @include font-Graphie(2.4, 3.6, 7);
      }

    }
  }
  .section-0-img {
    position: absolute;
    left: 3.2rem;
  }
  @media screen and (max-width: $screen-md-min){
    padding:5rem 1.7rem 0 1.7rem;
    .head-text{
      @include font-Graphie(2.8, 3.4, 7);
      padding-top:1.9rem;
      padding-bottom: 0;
    }
    .section-0-ul-list{
      display: flex;
      flex-direction: column;
      padding:3.6rem 0 0 0;
      .section-0-li-list-item{
        border:none;
        padding:2.8rem 0 0 0;
        &:first-child{
          padding-top:0;
        }
        &:last-child{
          padding-bottom:0;
        }
        
        .section-0-span{
          @include font-Graphie(1.6, 1.9, 4);
        }
        .section-0-p{
          @include font-Graphie(2, 3.6, 7);
        }
      }
    }
    .section-0-img{
      display: none;
    }
  }
  @media screen and (min-width:$screen-md-min) and (max-width:1279px){
    padding:0 2.4rem 0 2.9rem;
    .head-text{
      @include font-Graphie(3,4,7);
    }
    .section-0-ul-list{
      padding:3rem 0 0 0;
      .section-0-li-list-item{
        padding:1rem 1rem 1.6rem;
        .section-0-span{
          @include font-Graphie(1.4, 1.9, 4);
        }
        .section-0-p{
          @include font-Graphie(2, 2.4, 7);
        }
      }
    }
    .section-0-img{
      display: none;
    }
   
  }
}

.modular-section-progress-report-wrapper {
  background-color: #ffffff;
  padding: 18rem 0 6rem;
  .head-text {
    color: #1A1A1A;
    @include font-Graphie(3.6, 4.2, 7);
    text-align: center;
    margin-bottom: 1.6rem;
  }
  .progress-report-main-wrapper {
    @include flex-horizontal-center;
    max-width: 120rem;
    flex-wrap: wrap;
    margin: auto;
    .left-text-wrap {
      @include flex-vertical-center;
      padding-right: 4.5rem;
      flex: 0 0 35%;
      .progress-report-ul-list {
        .progress-report-li-list-item {
          position: relative;
          padding-left: 3.6rem;
          color: #1A1A1A;
          margin-bottom: 1.2rem;
          .tick-icon {
            @include custom-position-top-left(1rem, 0);
            display: block !important;
          } 
          h3 {
            @include font-Graphie(2, 2.8, 6);
            margin-bottom: 0.8rem;
          }
          p {
            @include font-Graphie(1.6, 2.8, 4);
            margin-bottom: 4rem;
          }
        }
      }
    }
    .right-img-wrap {
      text-align: right;
      flex: 0 0 65%;
    }
    .progress-report-primary-cta-button {
      width: fit-content;
      color: #ffffff;
      background-color: #090A4A;
      @include font-Graphie(1.8, 2.4, 6);
      padding: 1.6rem 4.5rem;
      border-radius: 3.2rem;
      margin-top: 3.6rem;
      margin-bottom: 6rem;
      cursor: pointer;
      &:hover {
        background-color: #141975;
      }
      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }
  @media screen and (max-width:$screen-md-min){
    padding:10rem 1.7rem 0 1.8rem !important;
    .head-text{
      @include font-Graphie(2.8,3.4,7);
      padding-bottom:4.8rem;
    }
    .progress-report-main-wrapper{
      flex-direction: column;
      align-items: center;
      .left-text-wrap{
        order:2;
        padding-right:0;
        padding-top:3.2rem;
        .progress-report-ul-list{

          .progress-report-li-list-item{
            margin-bottom:2rem;
            &:last-child{
              margin-bottom:0;
            }
            .tick-icon{
              top:0.5rem;
            }
            h3{
              @include font-Graphie(1.8,2.2,6);
            }
            p{
              @include font-Graphie(1.4,2.8,4);
              margin-bottom:0;
            }
            
          }
        }
      }
      .right-img-wrap{
        order:1;
        width:35rem;
        .progress-report-img{
          width:100%;
        }
      }
      .progress-report-primary-cta-button{
        display: none;
        order:3;
      }
    }
  }
  @media screen and (min-width:$screen-md-min) and (max-width:1279px){
    padding: 10rem 2.5rem 0 2.5rem;
    .head-text{
     @include font-Graphie(3,4,7);
     padding-bottom:5rem;
    }
    .progress-report-main-wrapper{
      .left-text-wrap{
        .progress-report-li-list-item{
          h3{
            @include font-Graphie(1.8,2.2,6);
          }
          p{
            @include font-Graphie(1.4,2.8,4);
          }
        }
      }
      .right-img-wrap{
        flex: 0 0 60%;
      }
    }

  }
   
   
}
.home-page-subject-grade-selectors{
  @include flex-center;
  gap: 2rem;
  flex-basis: wrap;
  .universe-learning-subject-list{
    border-left: 0.1rem solid $lightGreyBorder;
    padding-left: 2.4rem;
  }
  @media screen and (max-width: $screen-md-min) {
    display: block;
    .universe-learning-subject-list{
      padding:0;
      border: none;
    }
  }
}

.universe-learning-grade-list {
  margin-top: 3rem;
  margin-left: 1rem;
  height: 5.7rem;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  .grade-item {
    margin: 0 0.8rem;
    display: inline-block;
    text-align: center;
    height: 4.6rem;
    cursor: pointer;
    text-transform: capitalize;
    @include font-Graphie(1.6, 4.6, 6);
  }
  .grade-prek{
    width: 8.6rem;
    border-radius: 2.3rem;
  }
  .grade-other{
    width: 4.6rem;
    border-radius: 50%;
  }
  .grade-box-shadow {
    box-shadow: #4f52c3 0 0 0 0.2rem inset;
  }
  .grade-selected {
    background-color: #141975;
    box-shadow: #141975 0 0 0 0.2rem inset;
    color: #ffffff;
    &:hover {
      background-color: #141975;
    }
  }
  .grade-not-selected {
    color: #090A4A;
    &:hover {
      background-color: #DCDCEA;
    }
  }
  @media screen and (max-width: $screen-md-min) {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .grade-item:not(.grade-selected){
      border:2px solid #4F52C3;
      display: grid;
      place-content: center;
    }
    .grade-other{
      height:3.6rem;
      line-height:2.4;
      width:3.6rem;
      border-radius:50%;

    }
  }
}

.universe-learning-subject-list {
  margin-top: 2rem;
  .subject-item {
    margin: 0;
    display: inline-block;
    text-align: center;
    height: 4.6rem;
    width: 10rem;
    cursor: pointer;
    @include font-Graphie(1.6, 4.6, 6);
    &.sub-selected{
      border:none;
    }
  }
  &.content-type{
    margin-bottom: 2rem;
  }
  .content-type-item {
    width: 20rem;
  }
  .first-sub {
    border-radius: 2.3rem 0 0 2.3rem;
  }
  .other-sub {
    border-radius: 0 2.3rem 2.3rem 0;
  }
  .grade-box-shadow {
    box-shadow: #4f52c3 0 0 0 0.2rem inset;
  }
  .sub-selected {
    background-color: #141975;
    box-shadow: #141975 0 0 0 0.2rem inset;
    color: #ffffff;
    &:hover {
      background-color: #141975;
    }
  }
  .sub-not-selected {
    color: #090A4A;
    &:hover {
      background-color: #DCDCEA;
    }
  }
  @media screen and (max-width: $screen-md-min) {
    display: flex;
    justify-content: center;
    padding-bottom:2.8rem;
    .subject-item{
      border:2px solid #4F52C3;
      display: grid;
      place-content: center;
    }
  }
}
.home-page-slider{

 @media screen and (max-width:$screen-md-min){
    .slick-slider .slick-track, .slick-slider .slick-list{
      overflow: scroll;
    }
    .slick-slider .slick-arrow{
      display:none !important;
    }
   .slick-slider .slick-list .slick-track .slick-slide{
      filter:opacity(1);
    }
 }

 @media screen and (min-width:$screen-md-min) and (max-width:1279px){
  .slick-slider .slick-track, .slick-slider .slick-list{
    overflow: scroll;
  }
  .slick-slider .slick-list .slick-track .slick-slide{
    filter:opacity(1);
  }
  .slick-slider .slick-arrow{
    display:none !important;
  }
 }
 @media screen and (max-width:$screen-sm-min){
    .slick-slider .slick-track{
      transform:translate3d(0,0,0) !important;
    }
  }
}

.loggedout-home-universesection-padding-top{
  padding-top: 6.5rem;
  @media screen and (max-width: $screen-sm-min) {
    padding:0 1.7rem 0 1.8rem;
  }
}

@media screen and (max-width: $screen-xl-min){
  .reviewslidermobiletab-wrapper{
    text-align: center;
    .image-wrapper{
      height: 26.9rem;
      width: 26.2rem;
      margin: 4rem auto 0;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .content-wrapper{
      margin-top: 7rem;
      height: 19rem;
      @media screen and (max-width: $screen-sm-min) {
        height: 30rem;
      }
      .desc{@include font-Graphie(2.4, 3.6, normal);
  
        font-family: Graphie;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        margin-top: 2rem;
        color: black;
      }
      .name{
        @include font-Graphie(2, 1.3, 6);
        margin-top: 2rem;
        color: black;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.04rem;
      }
      .designation{
        @include font-Graphie(2, 4, 6);
        color: black;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.04rem;
      }
  
    }
  }
}

@media screen and (min-width: $screen-xl-min){
  .reviewsliderdesktop-review-box{
    @include custom-flex(space-between, center);
    height: 50rem;
    .review-image{
      height: auto;
      width: 40%;
      flex-shrink: 0;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .review-content{
      padding-left: 8rem;

      .desc{
        font-family: Graphie;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        margin-top: 2rem;
        color: black;
        @include font-Graphie(2.4, 3.6, normal);
      }
      .name {
        @include font-Graphie(2, 1.3, 6);
        margin-top: 2rem;
        color: black;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.04rem;
      }
      .designation {
        @include font-Graphie(2, 4, 6);
        color: black;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.04rem;
      }
    }

  }
}

@media screen and (max-width: $screen-xl-min){
  .loggedout-home-review-desktop, .le-slider-web {
    display :none;
  }
}

@media screen and (min-width: $screen-xl-min){
  .loggedout-home-review-mobile, .le-slider-mob {
    display :none;
  }
}

@media screen and (max-width: $screen-sm-min){
  .loggedout-home-review-desktop, .le-slider-web  {
    display :none;
  }
}

.thank-you {
  display: block;
  margin: 0 auto;
  @media screen and (min-width: $screen-lg-min) {
    width: 44rem;
  }
  @media screen and (min-width: $screen-sm-min) and (max-width: $screen-lg-min) {
    width: 40rem;
  }
  @media screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-min) {
    width: 28rem;
  }
}

.only-seo-visible{
  display: none;
}
